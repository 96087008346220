@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
@import "../css/variables.scss";

body {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $color-body-text;
}
.font-12 {
  font-size: 12px !important;
  line-height: 16px;
}
.font-14 {
  font-size: 14px;
  line-height: 18px;
}
.font-15 {
  font-size: 15px;
  line-height: 18px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-28 {
  font-size: 32px;
}
.heading-1 {
  font-size: 29px;
  font-weight: 500;
}

.font-bold {
  font-weight: $font-bold;
}
.font-medium {
  font-weight: $font-medium;
}
.font-light {
  font-weight: $font-light;
}
.font-regular {
  font-weight: $font-regular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-title-text;
  font-weight: $font-bold;
}

a {
  text-decoration: none;
  transition: 0.4s;
  &:hover {
    color: $color-secondary !important;
  }
}
.text-brand {
  color: $color-primary;
}
.text-brand-dark {
  color: $color-secondary;
}
.btn-primary {
  background-color: $color-primary;
  color: $color-title-text;
  font-size: 15px;
  font-weight: 500;
  font-weight: 500;
  height: 44px;
  border: 0;
  border-radius: 4px;
  padding: 7px 27px;
  line-height: 29px;
  &:hover {
    background-color: $color-secondary;
    color: #fff !important;
    svg path {
      stroke: #fff;
    }
  }
  &.btn-sm {
    height: 32px;
    font-size: 12px;
    padding: 4px 19px;
    font-weight: 400;
    line-height: 22px;
  }
}
.btn-outline-primary {
  @extend .btn-primary;
  background-color: transparent;
  border: 1px solid $color-primary;
  color: $color-primary;
  &:hover {
    background-color: $color-primary;
    border-color: $color-primary;
  }
}
.btn-secondary {
  @extend .btn-primary;
  background-color: #cacaca;
  color: #000;
  &:hover {
    background-color: $color-body-text-2;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.date-range-picker {
  border: 1px solid $color-primary;
  border-radius: 5px;
  padding: 8px;
  .react-datepicker__input-container {
    input {
      padding-right: 26px;
      font-size: 14px;
      border: 0;
      &:focus-visible {
        outline: none;
      }
    }
  }
}
.form-check-input:checked {
  background-color: $color-primary;
  border-color: $color-primary;
}
.tab-style-underline {
  .nav-link {
    border-bottom-color: #f9f9f9;
    color: $color-body-text;
    font-size: 14px;
    &.active {
      color: $color-primary;
      font-weight: 400;
    }
  }
}
.form-control:focus {
  box-shadow: none;
  border-color: #ddd;
}
.form-floating {
  > .form-control {
    min-height: 44px;
    padding: 11px 10px;
    height: 44px;
  }
  .form-select {
    height: 44px;
    font-size: 14px;
    padding: 18px 10px 8px;
    min-height: 44px;
  }
  > label {
    padding: 11px 12px;
    font-size: 14px;
    color: $color-body-text-3;
    z-index: 0 !important;
  }
  > .form-control:not(:placeholder-shown) {
    padding-top: 18px;
    padding-bottom: 5px;
    font-size: 14px;
    padding-left: 12px;
  }
  .form-control:focus ~ label::after,
  .form-control:not(:placeholder-shown) ~ label::after,
  .form-control-plaintext ~ label::after,
  .form-control:not(:placeholder-shown) ~ label,
  .form-control:focus ~ label,
  .form-select ~ label::after,
  .form-select ~ label {
    padding: 5px 12px;
    height: 27px;
    top: 6px;
    font-size: 12px;
    color: $color-body-text-3;
  }
}

// left menu css
.layout-veritcle-menu {
  display: flex;
  min-height: 100vh;

  .verticle-menu {
    background-color: $color-primary-light;
    width: 240px;
    height: 100vh;
    padding-top: 15px;
    z-index: 4;

    .menu-list {
      .menu-item {
        transition: 0.3s;
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 7px 16px;
        height: 44px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8px;
        color: $color-body-text;
        font-size: 15px;
        line-height: 16px;
        font-weight: 500;
        &:hover,
        &:focus {
          opacity: 1;
          background-color: #fff;
          color: $color-primary !important;
        }
        .menu-icon {
          width: 22px;
          height: 22px;
        }
      }

      .dropdown-toggle {
        @extend .menu-item;
        border: 0;
        background-color: transparent;
        &::after {
          border: 0;
        }

        &.show {
          background-color: $color-primary-light;
          opacity: 1;
        }
      }
      .dropend {
        text-align: center;
      }
      .dropdown-menu {
        left: -3px !important;
        border-radius: 0 6px 6px 0;
        background-color: $color-primary;
        .dropdown-item {
          color: #fff;
          font-size: 14px;
          padding: 9px 13px;
          margin-bottom: 3px;
          &:hover {
            background-color: #ffffff2b;
            color: #ffffff !important;
          }
        }
      }
    }
    .tooltip-inner {
      background-color: #343434 !important;
    }
  }
}
.component-loader {
  min-height: 300px;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 999;
  background-color: #00000014;
  // backdrop-filter: blur(3px);
  top: 0;
  left: 0;
  .loader-icon {
    background-color: transparent;
    img {
      width: 60px;
      height: 60px;
    }
  }
}
.page-content1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

// header
.site-header {
  position: fixed;
  top: 0;
  width: calc(100% - 240px);
  z-index: 9;
  .header-nav {
    .navbar {
      padding: 0;
    }
  }
  .header-nav {
    background-color: $color-primary-light;
    padding: 10px 15px;
    .v-devider {
      display: inline-block;
      width: 1px;
      background: #525252;
      height: 20px;
      margin: 0 15px;
    }
  }

  .profile-dd {
    .dropdown-menu {
      min-width: 200px;
      top: 52px;
      border: 0;
      box-shadow: 0px 3px 10px #0000002b;
      .dropdown-item {
        padding: 10px 15px;
        font-size: 14px;
      }
      .dropdown-item:hover a,
      .dropdown-item:hover .icon {
        color: #000 !important;
      }
    }
    .btn {
      background-color: transparent;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0;
      &::after {
        border: 0;
      }

      padding: 0;
    }
    .icon {
      width: 18px;
      height: 18px;
      margin-right: -4px;
      color: #4b4b4b;
    }
  }
  .help-btn {
    background-color: #f8f8ff;
    font-size: 14px;
    color: $color-primary;
    font-weight: $font-medium;
    height: 48px;
    padding: 8px 15px;
  }
}

// App Body
.app-body {
  width: calc(100% - 240px);
  background-color: #fff;
  position: relative;
}
.app-page {
  margin-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.plan-card {
  border-radius: 15px;
  height: 400px !important;
  // overflow-y: auto;
  .card-header {
    border-radius: 15px 15px 0 0;
  }
  .card-footer {
    border-radius: 0 0 15px 15px;
  }
  .recomended-badge {
    background-color: $color-secondary;
    color: #fff;
    font-size: 13px;
    padding: 4px 11px;
    border-radius: 12px;
    line-height: 15px;
    display: inline-block;
    position: absolute;
    right: 24px;
    top: -12px;
  }
  .active-badge {
    background-color:$color-body-text-3;
    color: #fff;
    font-size: 13px;
    padding: 4px 11px;
    border-radius: 12px;
    line-height: 15px;
    display: inline-block;
    position: absolute;
    right: 264px;
    top: -12px;
  }
}
.page-title {
  font-size: 20px;
  font-weight: $font-bold;
}
.file-drop-zone {
  padding: 65px 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s;
  border-radius: 16px;
  border: 1px dashed $color-primary;
  background: $color-primary-light;
  margin-bottom: 10px;
}

.file-drop-zone.dragging {
  border-color: $color-primary;
  background-color: #fff;
  box-shadow: 0px 0px 10px #0000001f;
}
.table-loader-fullScreen {
  position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color:  $color-primary;
}
.table-style-1 {
  .table-loader {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .loader-td {
    min-height: 300px;
  }
  thead th {
    background-color: $color-primary;
    color: $color-title-text;
    font-size: 14px;
    font-weight: $font-regular;
    padding: 8px 12px;
    &:first-child {
      border-radius: 7px 0 0 0;
    }
    &:last-child {
      border-radius: 0 7px 0 0;
    }
  }
  tbody {
    td {
      background-color: #f9f9f9;
      font-size: 14px;
      vertical-align: middle;
      border-color: #fff;
      padding: 8px 12px;
      .form-control {
        font-size: 14px;
        line-height: 17px;
      }
      .edit-btn {
        border: 1px solid $color-body-text-2;
        padding: 2px;
        border-radius: 4px;
        background-color: transparent;
        width: 30px;
        height: 30px;
        line-height: 20px;
        cursor: pointer;
        .icon {
        }
        &:hover {
          background-color: $color-body-text-2;
        }
      }
      .download-btn {
        border: 1px solid #636363;
        padding: 13px;
        border-radius: 4px;
        background-color: transparent;
        width: 30px;
        height: 30px;
        line-height: 20px;
        cursor: pointer;
        .icon {
        }
        &:hover {
          background-color: $color-body-text-2;
        }
      }
      .delete-btn {
        @extend .edit-btn;
        border-color: #db1818;
        color: #db1818;
        &:hover {
          background-color: #db1818;
        }
      }
    }
  }
}

.accordion {
  .accordion-item {
    border-radius: 0;
    background-color: transparent;
    border-left: 0;
    border-right: 0;
  }
  .accordion-button:not(.collapsed) {
    background-color: transparent;
  }
  .accordion-button {
    font-size: 14px;
    background-color: transparent;
    &:focus {
      box-shadow: none;
    }
  }
  .accordion-body {
    font-size: 14px;
    font-weight: 300;
  }
}
.phone_input {
  width: 625px;
  height: 41px;
  border-radius: 4px;
  border: 1px solid $color-body-text-3;
}
.prof-avtar-box {
  position: relative;
  margin: 0 auto;
  .edit-btn {
    background-color: #f2f2f2;
    width: 32px;
    height: 32px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 53px;
    .controler {
      width: 32px;
      height: 32px;
      border-radius: 30px;
      opacity: 0;
      &:hover {
        .edit-btn {
          cursor: pointer;
        }
      }
    }
    .icon {
      position: absolute;
      color: $color-primary;
      cursor: pointer;
    }
  }
}
// responsive css
@media (max-width: 767px) {
}
.verify-email-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.loader-container {
  margin-bottom: 20px; /* Space between loader and message */
}

/* Styling for the loading message */
.loading-message {
  font-size: 1.2rem;
  color: #2e2d2d;
  text-align: center;
}

.iconDownload {
  position: absolute;
  left: 7px;
  right: 0;
  top: 6px;
}
.badge {
  font-size: 12px;
  font-weight: $font-medium;
  background: #3579f6;
  &.badge-warning {
    background-color: #ffddc9 !important;
    color: #d06e37;
  }
  &.badge-danger {
    background-color: #fad9d9 !important;
    color: #d91f11;
  }
  &.badge-expired {
    background-color: #f1e3e3 !important;
    color: #f81303;
  }
  &.badge-primary {
    background-color: #d4e4fa !important;
    color: $color-primary;
  }
  &.badge-green {
    background-color: #c7ebd1 !important;
    color: #3c8952;
  }
  &.badge-light {
    background-color: #e4ebef !important;
    color: #566d7d;
  }
  &.badge-light1 {
    background-color: #72a7ff !important;
    color: #202c64;
  }
}
