$color-primary: #FF9CD9;
$color-primary-light: #FFF5FD;
$color-secondary: #604CC3;
$color-secondary-light: #FFFDFA;
$color-tertiary: #80C4E9;

$color-title-text: #0E0E0D;
$color-body-text: #383838;
$color-body-text-2: #636363;
$color-body-text-3: #969696;

$font-bold: 700;
$font-medium: 600;
$font-regular: 400;
$font-light: 300;